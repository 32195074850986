<template>
  <div class="gzjk">
    <div class="header-img">
      <div class="header-img-title">
        <img @click="$router.back(-1)" src="@/accesst/goods/back.png" alt="" srcset="">
        <span class="header-img-title-t">积分兑换</span>
        <span @click="$router.push({name: 'goodsList'})" class="header-img-title-d">兑换列表</span>
      </div>
    </div>
    <van-pull-refresh
      v-model="isLoading"
      class="fixedStyle"
      @refresh="onRefresh"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="offset"
        @load="onLoad"
      >
        <div v-for="(item, i) in tableList" :key="i">
          <div class="goods-item">
            <div class="goods-item-l">
              <img v-if="item.prodPic" :src="item.prodPic" alt="" srcset="">
              <img v-else src="@/accesst/goods/bg.png" alt="" srcset="">
            </div>
            <div class="goods-item-r">
              <div class="g-title">{{ item.prodName }}</div>
              <div class="g-process">
                <div class="g-process-line">
                  <van-progress :percentage="item.exchangeRatio" track-color="#F5EFEB" color="#FFC2A3" stroke-width="5" />
                </div>
                <div class="g-process-tip">已抢购{{item.exchangeRatio}}%</div>
              </div>
              <div class="g-price">价值¥{{ item.prodCost }}</div>
              <div class="g-handle">
                <div class="g-jifen">{{item.prodPoint}}积分</div>
                <div @click="open(item)" class="g-btn">兑换</div>
              </div>
            </div>
          </div>  
        </div>
      </van-list>
      <van-empty description="暂无数据" v-if="tableList.length === 0" />
    </van-pull-refresh>

    <!-- 兑换弹窗 -->
    <van-popup v-model="show" class="g-mask">
      <div class="g-mask-title">兑换：{{currentItem.prodName}}，价值¥{{ currentItem.prodCost }}</div>
      <div class="g-form">
        <div class="g-form-t">邮寄地址</div>
        <div class="g-form-line">
          <van-field :label-width="0" v-model="form.address" maxlength="80" :border="false" placeholder="请输入地址"></van-field>
        </div>
        <div class="g-form-t">手机号</div>
        <div class="g-form-line">
          <van-field :label-width="0" v-model="form.phone" maxlength="11" :border="false" placeholder="请输入手机号"></van-field>
        </div>
        <div class="g-form-t">收件人</div>
        <div class="g-form-line">
          <van-field :label-width="0" v-model="form.consignee" maxlength="12" :border="false" placeholder="请输入收件人"></van-field>
        </div>
        <div class="g-btns">
          <div @click="show=false, currentItem = {}" class="c-btn">取消</div>
          <div @click="submit()" class="s-btn">确定</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { List, PullRefresh, Empty, Progress, Popup, Field } from 'vant';
import { goodList, exchangeGood, getUserInfo } from '@/api/h5'
export default {
  components: {[List.name]: List, [Empty.name]: Empty, [PullRefresh.name]: PullRefresh, [Progress.name]: Progress, [Popup.name]: Popup, [Field.name]: Field},
  data() {
    return {
      show: false,
      timer: null,
      scrollTop1: 0,
      isLoading: false,
      offset: 50,
      pageStart: 1,
      limit: 10,
      loading: false,
      finished: false,
      tableList: [],
      currentItem: {},
      form: {
        consignee: '',
        phone: '',
        address: '',
        // status: 0
      },
      point: 0,
    };
  },
  created() {
    this.initUser()
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,   // 禁止背景点击
      duration: 300,  //展示时间，0为持续展示
      overlay: true,  //是否显示背景遮罩层
    })
  },
  mounted() {
    this.getFaultIndicator();
    window.addEventListener("scroll", this.scrollTops, true);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollTops);
    this.end();
  },
  methods: {
    initUser() {
      getUserInfo({}).then(res => {
        this.point = res ? res.point : 0;
      })
    },
    open(item) {
      if(item.prodPoint < this.point) {
        this.show = true;
        this.currentItem = item
      } else {
        this.$toast('您的积分不足')
      }     
    },
    submit() {
      console.log(this.form, 'form')
      if(!this.form.address) {
        this.$toast('请输入邮寄地址')
        return false
      }
      var regExp = /^1[3456789]\d{9}$/;
      if (!regExp.test(this.form.phone)) {
        this.$toast('请输入正确的手机号');
        return false
      }

      if(!this.form.consignee) {
        this.$toast('请输入收件人')
        return false
      }

      this.show = false;
      exchangeGood({...this.form, prodId: this.currentItem.id}).then(res => {
        this.$toast('兑换成功')
        this.initUser(); // 重置积分
        this.form = {
          consignee: '',
          phone: '',
          address: '',
          // status: 0
        }
      }).catch(e => {
        console.log(e, 'exchangeGood');
        this.form = {
          consignee: '',
          phone: '',
          address: '',
          // status: 0
        }
        this.$toast(e.msg || '兑换失败');
      })
    },
    start() {
      this.timer = setTimeout(() => {
        this.timer = null; // 清除计时器
        this.longPress(); // 触发长按事件
      }, 1000); // 设置长按时间为1秒
    },
    end() {
      if (this.timer) {
        clearTimeout(this.timer); // 清除计时器
      }
    },
    longPress() {
      document.querySelector(".fixedStyle").scrollTop = 0;
    },
    scrollTops() {
      this.scrollTop1 = document.querySelector(".fixedStyle").scrollTop;
    },
    disPlayBut() {
      if (document.querySelector(".fixedStyle").scrollTop !== 0) {
        if (document.querySelector(".fixedStyle").scrollTop < 50) {
          document.querySelector(".fixedStyle").scrollTop = 0;
        } else {
          document.querySelector(".fixedStyle").scrollTop -= 50;
        }
        setTimeout(() => {
          this.disPlayBut();
        }, 20);
      }
    },
    getFaultIndicator(res) {
      if (res == "search") {
        this.tableList = [];
        this.pageStart = 1;
        this.isLoading = false;
      }
      let params = {
        status: 0,
        pageNo: this.pageStart,
        pageSize: this.limit,
      };

      goodList(params).then(res=> {
        console.log(res, '---')
        if(res.list.length == this.limit) this.finished = false;
        else this.finished = true;
        this.tableList = this.tableList.concat(res.list);
        this.loading = false;
      })
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },
    onLoad() {
      if (this.isLoading) {
        this.tableList = [];
        this.pageStart = 1;
        this.isLoading = false;
      } else {
        this.pageStart = this.pageStart + 1;
      }
      //初始化列表
      this.getFaultIndicator();
    },
  },
};
</script>
<style lang="less" scoped>
.g-mask {
  width: 6.73rem;
  height: 8.15rem;
  background: #FFFFFF;
  border-radius: .12rem;
  .g-btns {
    margin-top: .5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .s-btn {
      width: 1.46rem;
      height: .8rem;
      background: #FF5700;
      border-radius: .08rem;
      text-align: center;
      line-height: .8rem;
      font-size: .27rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
    .c-btn {
      width: 1.46rem;
      height: .8rem;
      background: #ddd;
      border-radius: .08rem;
      text-align: center;
      line-height: .8rem;
      font-size: .27rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #ccc;
    }
  }
  .g-mask-title {
    padding: .38rem;
    height: .42rem;
    font-size: .31rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: .42rem;
  }
  /deep/ .van-cell {
    padding: .12rem .20rem;
    height: .62rem;
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: .38rem;
  }
   .g-form {
    padding: .2rem;
    .g-form-line {      
      height: .62rem;
      background: #FFFFFF;
      border-radius: .08rem;
      border: .02rem solid #EEEEEE;
    }
    .g-form-t {
      padding: .35rem 0 .2rem;
      font-size: .27rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
}
.gzjk {
  width: 100%;
  height: 100vh;
}
.header-img {
  width: 7.5rem;
  height: 3.32rem;
  background: url('../../accesst/goods/bg.png');
  background-size: 7.5rem 4.17rem;
  background-position: bottom center;
  .header-img-title {
    display: flex;
    justify-content: space-between;
    padding: .2rem .3rem 0 .38rem;
    img {
      width: .31rem;
      height: .31rem;
    }
    .header-img-title-t {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }
    .header-img-title-d {
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}

.goods-item {
  margin: .2rem auto 0;
  width: 6.92rem;
  height: 2.67rem;
  background: #F6F8F9;
  border-radius: .08rem;
  display: flex;
  justify-content: space-between;
  .goods-item-l {
    width: 2.45rem;
    height: 2.67rem;
    // border-radius: .08rem 0 0 .08rem;
    img {
      width: 2.45rem;
      height: 2.67rem;
      border-radius: .08rem 0 0 .08rem;
    }
  }
  .goods-item-r {
    width: 4.48rem;
    height: 2.67rem;
    .g-process {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .25rem .25rem .13rem;
      .g-process-line {
        width: 2.5rem;
      }
      .g-process-tip {
        font-size: .23rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #CCCCCC;
      }
    }
    .g-title {
      padding: .23rem .25rem 0;
      overflow: hidden;
      height: .42rem;
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: .42rem;
    }
    .g-price {
      padding: 0 .25rem;
      height: .33rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: .33rem;
    }

    .g-handle {
      padding: .2rem .25rem 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .g-jifen {
        width: 2.4rem;
        overflow: hidden;
        height: .42rem;
        font-size: .31rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FF5700;
        line-height: .42rem;
      }
      .g-btn {
        width: 1.46rem;
        height: .62rem;
        background: #FF5700;
        border-radius: .08rem;
        text-align: center;
        line-height: .62rem;
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
  }
}

/deep/ .van-pull-refresh__track {
  min-height: 100vh;
}
.fixedStyle {
  position: fixed;
  top: 3.32rem;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

</style>
